const App = {
	SetBackground: () => {
		$('[setBackground]').each(function () {
			var background = $(this).attr('setBackground')
			$(this).css({
				"background-image": "url(" + background + ")",
				"background-size": "cover",
				"background-position": "center center"
			})
		})
		$('[setBackgroundRepeat]').each(function () {
			var background = $(this).attr('setBackgroundRepeat')
			$(this).css({
				"background-image": "url(" + background + ")",
				"background-repeat": "repeat"
			})
		})
	},
	equalHeightElement: el => {
		let height = 0;
		let thisHeight = 0;
		$(el).each(function () {
			thisHeight = $(this).height();
			if (thisHeight > height) {
				height = thisHeight;
			}
		});
		$(el).height(height)
	},
	initLazyLoad: () => {
		return new LazyLoad({
			elements_selector: ".lazyload"
		});
	},
	ScrollTo: y => {
		$('html, body').animate({
			scrollTop: y
		}, 1000)
	},
	init: () => {
		App.SetBackground()
		App.initLazyLoad()
	}
}
let header = {
	scrollActive: function () {
		let height = $('header').height()
		if ($(window).scrollTop() > height) {
			$('header').addClass('active')
		} else {
			$('header').removeClass('active')
		}
	},
}

function initMapping() {
	$("header .main-wrap .left-wrap a").mapping({
		mobileWrapper: "header .mobile-wrap .center-mb-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: "header .main-wrap .left-wrap",
		desktopMethod: "appendTo",
		breakpoint: 1200
	});
	$("header .main-wrap .right-wrap").mapping({
		mobileWrapper: "header .menu-mobile",
		mobileMethod: "prependTo",
		desktopWrapper: "header .main-wrap .left-wrap",
		desktopMethod: "insertAfter",
		breakpoint: 1200
	});
	$("header .menu-mobile .cart").mapping({
		mobileWrapper: "header .mobile-wrap .right-mb-wrap .top-mb-wrap .user",
		mobileMethod: "insertAfter",
		desktopWrapper: "header .right-wrap .top-wrap .hot-line",
		desktopMethod: "insertAfter",
		breakpoint: 1200
	});

	$("header .menu-mobile .searchbox").mapping({
		mobileWrapper: "header .mobile-wrap .right-mb-wrap .bottom-mb-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: "header .main-wrap .right-wrap .top-wrap .seearch-box",
		desktopMethod: "appendTo",
		breakpoint: 1200
	});
	$("header .bottom-wrap .list-user").mapping({
		mobileWrapper: "header .mobile-wrap .right-mb-wrap .top-mb-wrap .user .list-user-mb",
		mobileMethod: "appendTo",
		desktopWrapper: "header .bottom-wrap",
		desktopMethod: "appendTo",
		breakpoint: 1200
	});
	$(".tng-san-pham .head-box .main-filter .list-price .list-price-2 li").mapping({
		mobileWrapper: ".tng-san-pham .head-box .main-filter .list-price .other .dropdown-menu ul",
		mobileMethod: "prependTo",
		desktopWrapper: ".tng-san-pham .head-box .main-filter .list-price .list-price-2",
		desktopMethod: "appendTo",
		breakpoint: 1500
	});

	$(".tng-san-pham .head-box .main-filter").mapping({
		mobileWrapper: ".tng-san-pham .list-filter",
		mobileMethod: "appendTo",
		desktopWrapper: ".tng-san-pham .head-box",
		desktopMethod: "appendTo",
		breakpoint: 576
	});
}

function initSlider() {
	let homeBanner = new Swiper('.tng-banner-home .swiper-container', {
		loop: true,
		speed: 1000,
		autoplay: {
			delay: 4500
		},
		pagination: {
			el: '.tng-banner-home .swiper-pagination',
			clickable: true
		}
	})
	let homeNewProduct = new Swiper('.tng-home-1 .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-1 .button-next',
			prevEl: '.tng-home-1 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 1
			},
			1025: {
				slidesPerView: 2
			},
			1200: {
				slidesPerView: 3
			},
		},
	})
	let homeNewProduct1 = new Swiper('.tng-home-2 .slide-pro-1 .swiper-container', {
		speed: 200,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-1 .button-next',
			prevEl: '.tng-home-2 .slide-pro-1 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct2 = new Swiper('.tng-home-2 .slide-pro-2 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-2 .button-next',
			prevEl: '.tng-home-2 .slide-pro-2 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct3 = new Swiper('.tng-home-2 .slide-pro-3 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-3 .button-next',
			prevEl: '.tng-home-2 .slide-pro-3 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct4 = new Swiper('.tng-home-2 .slide-pro-4 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-4 .button-next',
			prevEl: '.tng-home-2 .slide-pro-4 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct5 = new Swiper('.tng-home-2 .slide-pro-5 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-5 .button-next',
			prevEl: '.tng-home-2 .slide-pro-5 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct6 = new Swiper('.tng-home-2 .slide-pro-6 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-6 .button-next',
			prevEl: '.tng-home-2 .slide-pro-6 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNewProduct7 = new Swiper('.tng-home-2 .slide-pro-7 .swiper-container', {
		speed: 200,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-2 .slide-pro-7 .button-next',
			prevEl: '.tng-home-2 .slide-pro-7 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let homeNews = new Swiper('.tng-home-3 .swiper-container', {
		speed: 1000,
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: '.tng-home-3 .button-next',
			prevEl: '.tng-home-3 .button-prev'
		},
		slidesPerView: 4,
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 7,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1200: {
				slidesPerView: 4
			},
		},
	})
	let galleryThumbs = new Swiper('.tng-san-pham-ct-1 .main-slide-bottom .swiper-container', {
		spaceBetween: 10,
		slidesPerView: 5,
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true
	})
	let galleryTop = new Swiper('.tng-san-pham-ct-1 .main-slide-top .swiper-container', {
		spaceBetween: 10,
		navigation: {
			nextEl: '.tng-san-pham-ct-1 .button-next',
			prevEl: '.tng-san-pham-ct-1 .button-prev',
		},
		thumbs: {
			swiper: galleryThumbs
		},
		on: {
			init: function () {
				ZoomImg();
			},
			slideChangeTransitionEnd: function () {
				ZoomImg();
			},
		},
	})
	let uuDaiSlide = new Swiper('.tng-uu-dai-3 .swiper-container', {
		loop: true,
		spaceBetween: 30,
		speed: 1000,
		autoplay: {
			delay: 4500
		},
		navigation: {
			nextEl: '.tng-uu-dai-3 .button-next',
			prevEl: '.tng-uu-dai-3 .button-prev',
		},
	})
	let vctSlide = new Swiper('.about-1 .swiper-container', {
		loop: true,
		spaceBetween: 30,
		speed: 1000,
		autoplay: {
			delay: 4500
		},
		navigation: {
			nextEl: '.about-1 .button-next',
			prevEl: '.about-1 .button-prev',
		},
	})
	let sPK1 = new Swiper('.tng-san-pham-ct-3 .product-slide-1 .swiper-container', {
		speed: 1000,
		slidesPerView: 6,
		loop: true,
		navigation: {
			nextEl: '.tng-san-pham-ct-3 .product-slide-1 .button-next',
			prevEl: '.tng-san-pham-ct-3 .product-slide-1 .button-prev'
		},
		breakpoints: {
			576: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			},
		},
	})
	let sPK2 = new Swiper('.tng-san-pham-ct-3 .product-slide-2 .swiper-container', {
		speed: 1000,
		slidesPerView: 6,
		loop: true,
		navigation: {
			nextEl: '.tng-san-pham-ct-3 .product-slide-2 .button-next',
			prevEl: '.tng-san-pham-ct-3 .product-slide-2 .button-prev'
		},
		breakpoints: {
			576: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			},
		},
	})
	let sPK3 = new Swiper('.tng-san-pham-ct-3 .product-slide-3 .swiper-container', {
		speed: 1000,
		slidesPerView: 6,
		loop: true,
		navigation: {
			nextEl: '.tng-san-pham-ct-3 .product-slide-3 .button-next',
			prevEl: '.tng-san-pham-ct-3 .product-slide-3 .button-prev'
		},
		breakpoints: {
			576: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			},
		},
	})
	let sPK4 = new Swiper('.tng-san-pham-ct-3 .product-slide-4 .swiper-container', {
		speed: 1000,
		slidesPerView: 6,
		loop: true,
		navigation: {
			nextEl: '.tng-san-pham-ct-3 .product-slide-4 .button-next',
			prevEl: '.tng-san-pham-ct-3 .product-slide-4 .button-prev'
		},
		breakpoints: {
			576: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			},
		},
	})
	let sPK5 = new Swiper('.tng-san-pham-ct-3 .product-slide-5 .swiper-container', {
		speed: 1000,
		slidesPerView: 6,
		loop: true,
		navigation: {
			nextEl: '.tng-san-pham-ct-3 .product-slide-5 .button-next',
			prevEl: '.tng-san-pham-ct-3 .product-slide-5 .button-prev'
		},
		breakpoints: {
			576: {
				slidesPerView: 1,
			},
			768: {
				slidesPerView: 2,
			},
			1025: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1366: {
				slidesPerView: 5,
			},
		},
	})
}

function initHeight() {
	// App.equalHeightElement('.chuyen-khoa-ct .other-service .list ul li')
}

function adjustProductAmount() {
	$(".btn-dec").click(function (e) {
		e.preventDefault();
		var t = $(this).next(),
			r = parseInt(t.val());
		r <= 0 ? r = 0 : r--, t.val(r), t.trigger("change")
	}), $(".btn-inc").click(function (e) {
		e.preventDefault();
		var t = $(this).prev(),
			r = parseInt(t.val());
		r++, t.val(r), t.trigger("change")
	}), $(".quantity .minus").click(function (e) {
		e.preventDefault();
		var t = $(this).next(),
			r = parseInt(t.val());
		r <= 0 ? r = 0 : r--, t.val(r), t.trigger("change")
	}), $(".quantity .plus").click(function (e) {
		e.preventDefault();
		var t = $(this).prev(),
			r = parseInt(t.val());
		r++, t.val(r), t.trigger("change")
	})
}

function socialShare() {
	$("#shareSocial").jsSocials({
		showLabel: false,
		showCount: false,
		shares: [{
				share: "twitter",
				logo: "fab fa-twitter"
			},
			{
				share: "facebook",
				logo: "fab fa-facebook-f"
			}
		],

	});
}

function toggleMenuMB() {
	$('header .button-hambuger').on('click', function () {
		$('header .menu-mobile').slideDown()
		$('.backdrop').addClass('active')
	})
}

function backDropClose() {
	$('.backdrop').on('click', function () {
		$('header .menu-mobile').slideUp()
		$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .user .list-user-mb').slideUp()
		$('header .menu-mobile-right').slideUp()
		$('.backdrop').removeClass('active')
	})
}

function toggleNavMb() {
	$('.box-menu .title-menu').on('click', function () {
		if ($(window).width() < 1025) {
			$('.box-menu .list-menu').slideToggle()
		}
	})
}
let boxMenuNav = {
	checkMenuScroll: () => {
		var nav = $('.box-menu')
		if (nav.length) {
			var scrollTop = $(window).scrollTop(),
				header = $('header').outerHeight(),
				offset = nav.offset().top
			if (((offset - header) - scrollTop) <= 0) {
				$('.box-menu').addClass('sticky')
			} else {
				$('.box-menu').removeClass('sticky')
			}
		}
	}
}

function showMenuPro() {
	$('.tng-san-pham .box-menu-product ul .dropdown-list>p').on('click', function () {
		if ($(this).parent().hasClass('active')) {
			$('.tng-san-pham .box-menu-product ul li').removeClass('active').find('.dropdown-menu').slideUp()
		} else {
			$('.tng-san-pham .box-menu-product ul li').removeClass('active').find('.dropdown-menu').slideUp()
			$(this).parent().addClass('active').find('.dropdown-menu').slideDown()
		}
	})
	$('.tng-san-pham .box-menu-product ul .dropdown-list .dropdown-list-2>a').on('click', function (e) {
		e.preventDefault();
		if ($(this).parent().hasClass('active')) {
			$('.tng-san-pham .box-menu-product ul li.dropdown-list-2').removeClass('active').find('.dropdown-menu-2').slideUp()
		} else {
			$('.tng-san-pham .box-menu-product ul li.dropdown-list-2').removeClass('active').find('.dropdown-menu-2').slideUp()
			$(this).parent().addClass('active').find('.dropdown-menu-2').slideDown()
		}
	})
}

function tabslet() {
	$('.tabs').tabslet()
}

function stickyNavSP() {
	var nav = $('.nav-sticky-mb')
	if (nav.length) {
		var offsetTop = nav.offset().top
		var headerOffsetTop = $("header").height()

		if ($(window).scrollTop() > (offsetTop - headerOffsetTop)) {
			$(".nav-sticky-mb").addClass("sticky").css('top', headerOffsetTop)
		} else {
			// $(".nav-sticky-mb").removeClass("sticky").removeAttr('style')
		}
	}
}

function showNavProMB() {
	$('.box-menu-product .title').on('click', function () {
		if ($(window).width() <= 1024) {
			$('.box-menu-product .list-menu').slideToggle()
		}
	})
	$('.box-color-product .title').on('click', function () {
		if ($(window).width() <= 1024) {
			$('.box-color-product .list-color').slideToggle()
		}
	})
}

function showFilterMB() {
	$('.tng-san-pham .filter-mobile .icon').on('click', function () {
		$('.list-filter').slideToggle()
	})
}
$(document).ready(function () {
	initMapping()
	initSlider()
	adjustProductAmount()
	// productDetailSlide()
	initHeight()
	App.init()
	copyHtml()
	togglemenuRight()
	// social
	socialShare()
	// toggle nav
	toggleMenuMB()
	toggleNavMb()
	toggleUserMB()
	showMenuPro()
	backDropClose()
	// tabslet
	tabslet()
	// mobile
	showNavProMB()
	showFilterMB()
	// 
	changeIcon()
	changeCustomer()
	hoverChangeImgProduct()
	//cart
	toggleCart()
	checkCartNumber()
	showBillForm()
	// comemt
	commentpanel()
	viewmoreDetail()
	moveHtml()
	$(".searchbox-wrap-mobile input").focus(function () {
		console.log(1);
		$(this).keyup(function (e) {
			console.log(2);
			console.log(e.keyCode);
			if(e.keyCode == 13) {
				console.log(3);
				$(this).next().trigger("click");
			}
		});

	});
})


function ZoomImg() {
	if ($(window).width() > 1024) {
		$(".swiper-slide-active .zoom-img").ezPlus({
			gallery: "gallery_01",
			cursor: "pointer",
			easing: 'true',
			galleryActiveClass: "active",
			imageCrossfade: true,
			loadingIcon: "http://www.elevateweb.co.uk/spinner.gif",
			zoomWindowWidth: "300",
			zoomWindowHeight: "300",
			borderSize: "1",
			borderColour: "#c81023",
			zoomWindowFadeIn: 500,
			zoomWindowFadeOut: 500,
			lensFadeIn: 500,
			lensFadeOut: 500

		});
	}
}

function changeIcon() {
	$('footer .footer-top .subscribe .subscribefrm button').html('<em class="fas fa-arrow-right"></em>')

	// $('footer .footer-top .subscribe .subscribefrm input.subscribeemail').attr('placeholder', 'Đăng ký nhận bản tin...')
	$('footer .footer-top .subscribe .subscribefrm button').on('click', function () {
		setInterval(function () {
			$('footer .footer-top .subscribe .subscribebutton').html('<em class="fas fa-arrow-right"></em>')
		}, 100)
	})
}

function changeCustomer() {
	$('.tng-khach-hang .dropdown-box .dropdown-menu ul li').on('click', function () {
		let attr = $(this).attr('data-id')
		let title = $(this).find('a').html()
		// console.log(title)
		$('.tng-khach-hang .list-item').css('display', 'none')
		$('.tng-khach-hang .list-item[data-news=' + (attr) + ']').css('display', 'flex')
		$('.tng-khach-hang .filter-customer .box-active .text-active').html(title)
	})
}

function toggleCart() {
	$('.cart .cart-toggle').on('click', function () {
		$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .cart .dropdown-cart, header .right-wrap .top-wrap .cart .dropdown-cart').slideToggle()
	})
	$('.tng-san-pham-ct-1 .order .buy-price .add-cart .btn-add-cart').on('click', function () {
		$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .cart .dropdown-cart, header .right-wrap .top-wrap .cart .dropdown-cart').slideDown()
	})
}

function togglemenuRight(){
	if($(window).width()<576){
		$('header .mobile-wrap .right-mb-wrap').on('click', function(){
			$('header .menu-mobile-right').slideToggle();
			$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .cart .dropdown-cart').slideUp();
			// toggleCart();
			// $('header .backdrop').addClass('active');
		});
	}
}

function commentpanel() {
	$('.commentpanel').clone().appendTo(".main-commentpanel")
}

function viewmoreDetail() {
	$('.tng-san-pham-ct-2 .brief-content .view-more').on('click', function () {
		$(this).addClass('active')
		$('.tng-san-pham-ct-2 .brief-content').addClass('active')
	})
	$('.tng-san-pham-ct-2 .product-attrbutes .view-more').on('click', function () {
		$(this).addClass('active')
		$('.tng-san-pham-ct-2 .product-attrbutes').addClass('active')
	})
}

function checkCartNumber() {
	var cartNumber = $('header .right-wrap .top-wrap .cart .cart-number span').val()
	console.log(cartNumber)
}

function toggleUserMB() {
	$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .user .Module-306 a').on('click', function (e) {
		e.preventDefault()
		$('header .mobile-wrap .right-mb-wrap .top-mb-wrap .user .list-user-mb').slideDown()
		$('header .backdrop').addClass('active')
	});
	$('.tng-san-pham .foot-box .category-toggle').on('click', function(){
		$('.tng-san-pham .nav-sticky-mb>.list-filter').removeClass('active')
		$('.tng-san-pham .nav-sticky-mb>.row').toggleClass('active')
	})
	$('.tng-san-pham .foot-box .filter-toggle').on('click', function(){
		$('.tng-san-pham .nav-sticky-mb>.row').removeClass('active')
		$('.tng-san-pham .nav-sticky-mb>.list-filter').toggleClass('active')
	})
}

function showBillForm() {
	$('.export-bill .title').on('click', function () {
		if ($('.export-bill .title .check-group input:checked').length > 0) {
			$('.export-bill .bill-form').slideDown()
		} else {
			$('.export-bill .bill-form').slideUp()
		}
	})
}

function hoverChangeImgProduct() {
	$('.product-item').hover(function () {
		var imgChangeUrl = $(this).find('.image img')
		$(this).find('.list-color ul li .color-link').hover(function () {
			var imgUrl = $(this).attr('imageurl')
			imgChangeUrl.attr('src', imgUrl)
		})
	})
}

function moveHtml(){
	$('.tng-san-pham .foot-box').appendTo('.tng-san-pham');
}
function copyHtml(){
	$("header .mobile-wrap .right-mb-wrap").clone().prependTo("header .menu-mobile-right");
	$('header .menu-mobile-right .bottom-mb-wrap .searchbox').remove();
	if ($(window).width() < 575.89) {
		$('header .mobile-wrap .right-mb-wrap .bottom-mb-wrap .searchbox input').removeAttr('onfocus').removeAttr('onblur').removeAttr('id').attr('readonly', 'readonly').attr('disabled', 'disabled');
		$('header .mobile-wrap .right-mb-wrap .bottom-mb-wrap .searchbox button').removeAttr('onclick').removeAttr('id');
	}
	$('header .menu-mobile-right .searchbox-wrap-mobile').appendTo('header .menu-mobile-right .bottom-mb-wrap')
}

$(document).on('scroll', function () {
	header.scrollActive()
	if ($('.box-menu').length > 0) {
		boxMenuNav.checkMenuScroll()
	}
	stickyNavSP()
})
